<template>
  <div>
    <a-modal
      :visible="visible"
      title="编辑"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="名称">
          <a-input
            v-decorator="[
              'name',
              {
                initialValue: item.name,
                rules: [
                  { required: true, message: '请输入名称' },
                  { max: 64, message: '超出最大长度(64)' },
                ],
              },
            ]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="生产线">
          <a-select
            v-decorator="[
              'production_line',
              {
                initialValue: item.production_line,
                rules: [{ required: true, message: '请选择生产线' }],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option value="a">名创 A</a-select-option>
            <a-select-option value="b">名创 B</a-select-option>
            <a-select-option value="c">名创 C</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="道口号">
          <a-input-number
            v-decorator="['number', { initialValue: item.number, rules: [{ required: true, message: '请输入道口号' }] }]"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="con">
          <a-input
            v-decorator="['con_number', { initialValue: item.con_number, rules: [{ max: 64, message: '超出最大长度(64)' }] }]"
            :allowClear="true"
          />
        </a-form-item>
        <a-form-item label="最大分拣数量">
          <a-input-number
            v-decorator="['max_sorting_quantity', { initialValue: item.max_sorting_quantity }]"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="最大分拣重量">
          <a-input-number v-decorator="['max_sorting_weight', { initialValue: item.max_sorting_weight }]" style="width: 100%" />
        </a-form-item>
        <a-form-item label="备注">
          <a-input
            v-decorator="['remark', { initialValue: item.remark, rules: [{ max: 256, message: '超出最大长度(256)' }] }]"
            :allowClear="true"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { crossingUpdate } from "@/apis/data";

export default {
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      confirmLoading: false,
      dataForm: null,
    };
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;
          crossingUpdate({ id: this.item.id, ...values })
            .then((data) => {
              this.$emit("update", data);
              this.$message.success("编辑成功");
              this.handleCancel();
            })
            .catch((error) => {
              if (error.non_field_errors && error.non_field_errors.length > 0) {
                this.$message.error(error.non_field_errors[0]);
              } else {
                const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                  acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                  return acc;
                }, {});
                this.dataForm.setFields(errorFields);
              }
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
  },
};
</script>

<style scoped></style>
